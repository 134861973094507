<template>
  <ValidationObserver tag="div" class="single-answer-matrix column is-10" v-slot="{ errors }">
    <div class="question-title">
      <span v-if="showQuestionCode">{{ question.code }}: </span><span v-html="question.question[lang]"></span>
      <p v-if="getFirstError(errors)" class="help is-danger">{{ getFirstError(errors) }}</p>
    </div>
    <div class="card">
      <div class="card-content">
        <table class="table">
          <colgroup>
            <col v-for="index in cols" :key="index" :width="colWidth">
          </colgroup>
          <tr>
            <td></td>
            <td v-for="answer in question.answers" :key="answer.id" v-html="answer.answer[lang]"></td>
          </tr>
          <ValidationProvider v-for="subQuestion in question.sub_questions" :key="subQuestion.id"
                              :rules="validationRules"
                              v-slot="{ errors }"
                              tag="tr">
            <td :class="{'has-text-danger has-text-weight-bold':errors[0]}">
              <div v-html="subQuestion.question[lang]"></div>
            </td>
            <td v-for="answer in question.answers" :key="answer.id">
              <b-radio
                  :value="value[questionKey(question.qid, subQuestion.code)]"
                  @input="onChange(questionKey(question.qid, subQuestion.code), $event)"
                  :native-value="answer.code"
                  :disabled="loading"
              >
              </b-radio>
            </td>
          </ValidationProvider>
        </table>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>

import { QuestionMixin } from '@/mixins/question'

export default {
  name: 'SingleAnswerMatrix',
  mixins: [QuestionMixin],
  methods: {
    onChange (key, value) {
      this.$emit('input', { [key]: `${value}` })
    },
    getFirstError (errors) {
      const firstExistsErrors = Object.values(errors).find(item => item[0])
      if (firstExistsErrors) return firstExistsErrors[0]
    }
  },
  computed: {
    cols () {
      return this.question.answers.length + 1
    },
    colWidth () {
      return `${Math.round(100 / this.cols)}%`
    }
  }
}
</script>
<style lang="scss">
.single-answer-matrix {
  color: #595959;

  .b-radio.radio {
    margin-right: 0;

    .control-label {
      display: none;
    }
  }

  table {
    td:not(:first-child) {
      text-align: center;
    }

    tr:not(:first-child) {
      td:not(:first-child) {
        vertical-align: middle;
      }
    }

    tr:last-child td {
      border-bottom: none;
    }
  }
}
</style>
